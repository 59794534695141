import React, { createContext, useContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Utilisez localStorage pour récupérer la valeur de isLoggedIn lors du chargement initial
    return JSON.parse(localStorage.getItem("isLoggedIn")) || false;
  });

  useEffect(() => {
    // Stockez la valeur de isLoggedIn dans localStorage à chaque changement
    localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  const handleLogin = (email, password) => {
    // Ici, vous pouvez placer votre logique de connexion
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    // Ici, vous pouvez placer votre logique de déconnexion
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
