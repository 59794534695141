import React from "react";
import { InlineWidget } from "react-calendly";
import Nav from "../../components/Nav/Nav";
import "./Calendar.css";

const Calendar = () => {
  return (
    <div className="calendar">
      <Nav />
      <InlineWidget
        url="https://calendly.com/avocat-dore
"
      />
    </div>
  );
};

export default Calendar;
