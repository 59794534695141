import React from "react";
import "../Card/Card.css";
import { NavLink } from "react-router-dom";

function Card(props) {
  return (
    <div className="card">
      <NavLink className="Cv-card" to={`/article/${props.card.id}`}>
        {props.card.photo == null && (
          <img
            className="card-image-default"
            src="./images/logocabinet.png"
            alt="logocabinet"
          />
        )}
        {props.card.photo != null && (
          <img
            className="card-image"
            src={props.card.photo}
            alt={props.card.title}
          />
        )}
        <div className="card-header">
          <div className="card-title-group">
            <h5 className="card-title">{props.card.title}</h5>
            <div
              className="card-text"
              dangerouslySetInnerHTML={{ __html: props.card.content }}
            />
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export default Card;
