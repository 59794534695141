import React from "react";
import "./style.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <img
          src="../images/logocabinet_compress.png"
          className="footer_logo"
          alt="logo"
        ></img>

        <div className="footer_links">
          <ul>
            <li>
              <Link to="/">
                <div className="option">Accueil</div>
              </Link>
            </li>
            <li>
              <Link to="/apropos">
                <div className="option">A propos</div>{" "}
              </Link>
            </li>
            <li>
              <Link to="/dashboard">
                <div className="option">Blog</div>
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/rdv">
                <div className="option">Rendez-vous</div>
              </Link>
            </li>
            <li>
              <Link to="/contact">
                <div className="option">Contact</div>
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/confidentialité">
                <div className="option">Politique de confidentialité</div>
              </Link>
            </li>
            <li>
              <Link to="/légal">
                <div className="option">Mentions légales</div>
              </Link>
            </li>
          </ul>
          <ul className="footer_socials">
            <li>
              <a href="/" className="footer_social">
                <FacebookIcon />
              </a>
            </li>
            <li>
              <a href="/" className="footer_social">
                <InstagramIcon />
              </a>
            </li>
            <li>
              <a href="/" className="footer_social">
                <TwitterIcon />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/camille-dor%C3%A9-aa19ba155/"
                className="footer_social"
              >
                <LinkedInIcon />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer_copy">Cabinet Doré - tous droits réservés</div>
    </div>
  );
};

export default Footer;
