import React, { useEffect, useState } from "react";
import Nav from "../../components/Nav/Nav";
import AddBoxIcon from "@mui/icons-material/AddBox";
import axios from "axios";
import Card from "../../components/Card/Card";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../components/AuthProvider";

function Dashboard(props) {
  const { isLoggedIn } = useAuth();
  const [allPost, setAllPost] = useState([]);
  function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }
  const token = getCookie("_auth");

  useEffect(() => {
    axios
      .get("https://api.dore-avocat.fr/api/post", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setAllPost(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  console.log(allPost);

  return (
    <div className="dashboard">
      <Nav />
      {isLoggedIn && <h1 className="dashboard-title">Espace privé</h1>}
      {!isLoggedIn && <h1 className="dashboard-title">Blog</h1>}

      <div>
        {allPost.length === 0 ? (
          <p className="blog-null">
            {" "}
            Actuellement, le blog ne comporte aucun article. Nous vous invitons
            à revenir ultérieurement pour découvrir nos prochaines publications.
          </p>
        ) : (
          <div className="dashboard-grid">
            {isLoggedIn && (
              <div className="new-card">
                <Link to="/article">
                  <div className="new-card-content">
                    <AddBoxIcon />
                    <h2>Ecrire un nouvel article</h2>
                  </div>
                </Link>
              </div>
            )}

            {allPost.map((item, i) => (
              <div className="dashboard-card">
                <Card card={item} key={i} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
