import React from "react";
import Nav from "../../components/Nav/Nav";
import "./Privacy.css";
const Privacy = () => {
  return (
    <div>
      <Nav />
      <div className="privacy">
        <h2>Politique de confidentialité</h2>
        <p>
          Le Cabinet Doré, est conscient de l’importance de la confidentialité
          et du respect de vos données personnelles. La présente « Politique de
          confidentialité » vous informe de manière transparente de la façon
          dont Le cabinet traite vos données personnelles.
        </p>
        <p>
          Le cabinet s’engage à respecter le Règlement (UE) 2016/679 du
          Parlement européen et du Conseil du 27 avril 2016 (ci-après « RGPD »)
          ainsi que toute autre réglementation applicable.
        </p>
        <p>
          Le responsable de traitement est le cabinet Doré, situé au 22 rue
          Lamarck - 80000 Amiens .
        </p>
        <p>
          En utilisant notre Site, vous consentez à la présente politique de
          confidentialité.
        </p>
        <h3>
          1. Quelles sont les données personnelles collectées par le cabinet
          Doré ?
        </h3>
        <p>
          Conformément au principe de minimisation des données, le cabinet
          collecte les informations suivantes : Les données d’identification
          telles que le nom, le prénom, l'adresse e-mail.
        </p>
        <h3>
          2. Les informations collectées sont utilisées aux fins suivantes :
        </h3>
        <p>
          <ul>
            <li>
              Pour contacter nos clients et répondre à leurs demandes via le
              formulaire de contact.
            </li>
            <li>
              Gérer l'accès à l'espace privé réservé aux membres du cabinet pour
              la création d'articles de blog.
            </li>
          </ul>
        </p>
        <h3>
          3. Quelle est la durée de conservation de vos données personnelles ?
        </h3>{" "}
        <p>
          Toutes les données collectées dans le cadre d'une prospection seront
          conservées jusqu'au retrait du consentement ou pendant une durée
          maximale de 3 ans à compter du dernier contact des personnes avec le
          cabinet.
        </p>
        <h3>
          4. Comment le cabinet Doré assure la sécurité de vos données
          personnelles?
        </h3>{" "}
        <p>
          Le cabinet met en œuvre toutes les mesures techniques, physiques et
          organisationnelles nécessaires pour prévenir, dans la mesure du
          possible, toute violation de sécurité entraînant, de manière
          accidentelle ou illicite, la destruction, la perte, l'altération, la
          divulgation non autorisée de données à caractère personnel transmises,
          conservées ou traitées d'une autre manière, ou l'accès non autorisé à
          de telles données.
        </p>
        <p>
          L’accès aux données à caractère personnel est limité aux personnel du
          Cabinet.
        </p>
        <h3>5. Quels sont vos droits concernant vos données personnelles ?</h3>{" "}
        <ul>
          <li>
            Droit d’accès : vous pouvez demander au cabinet l’accès à vos
            données personnelles. Nous vous les communiquerons dans un format
            compréhensible.
          </li>
          <li>
            Droit à la portabilité : vous pouvez demander au cabinet de
            récupérer les données personnelles que vous avez fournies, si elles
            ont été nécessaires au traitement auquel vous avez consenti, ceci
            afin d’en disposer ou pour les transmettre à un tiers.
          </li>
          <li>
            Droit de rectification : vous avez le droit de rectifier, compléter,
            mettre à jour les données vous concernant.
          </li>
          <li>
            Droit à l’effacement : vous pouvez demander l’effacement de vos
            données personnelles, sous réserve du respect d’une obligation
            légale par le cabinet.
          </li>
          <li>
            Droit d’opposition: vous pouvez vous opposer à tout moment à ce que
            le cabinet utilise certaines de vos données en indiquant des raisons
            tenant à votre situation particulière, sauf en cas de prospection, à
            laquelle vous pouvez vous opposer sans motif.
          </li>
          <li>
            Droit au retrait du consentement: vous pouvez retirer votre
            consentement à tout moment, si celui-ci a été préalablement donné.
          </li>
          <li>
            Droit à la limitation : vous pouvez demander la suspension du
            traitement de vos données personnelles, notamment si vous contestez
            l’exactitude des données utilisées ou si vous vous opposez à ce que
            vos données soient traitées.
          </li>
        </ul>
        <h3>6. Comment exercer vos droits ?</h3>
        <p>
          Pour exercer ces droits ou pour toute question sur le traitement de
          vos données accompagné d’une copie de votre titre d’identité, vous
          pouvez nous contacter par courrier au 22 rue Lamarck - 80000 Amiens .
          Mail à avocat.dore@gmail.com Si vous estimez, après nous avoir
          contacté, que vos droits ne sont pas respectés, vous pouvez adresser
          une réclamation à la CNIL.
        </p>
        <h3>7. Gestion des cookies</h3>
        <p>
          Les cookies sont des petits fichiers déposés dans le navigateur de
          votre ordinateur ou terminal lors de votre navigation sur le site pour
          rendre votre expérience plus efficace. Lors de votre navigation sur
          notre site des cookies peuvent s’installer sur votre logiciel de
          navigation.
        </p>
        <p>
          Notre site utilise des cookies strictement nécessaires permettant aux
          services principaux du site de fonctionner de manière optimale. Nous
          utilisons également des cookies d'authentification/connexion pour
          faciliter l'accès à l'espace membre et des cookies nécessaires au
          fonctionnement de Calendly. Pour désactiver les cookies sur votre
          navigateur, consulter le lien suivant :
          https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser. Les
          cookies techniques suivants sont utilisés sur notre site :
        </p>
        <table>
          <thead>
            <tr>
              <th>Nom du cookie</th>
              <th>Finalité(s)</th>
              <th>Durée de conservation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>__cf_bm</td>
              <td>
                Ce cookie est utilisé pour faire la distinction entre les
                humains et les robots. Cela est bénéfique pour le site web afin
                de générer des rapports valides sur l'utilisation de leur site{" "}
              </td>
              <td>30 minutes</td>
            </tr>
            <tr>
              <td>__cfruid</td>
              <td>
                Cookie associé aux sites utilisant CloudFlare, utilisé pour
                identifier le trafic web de confiance
              </td>
              <td>Durée de la session</td>
            </tr>
            <tr>
              <td>
                _auth; _auth_storage; _auth_state; _auth_storage; isLoggedIn{" "}
              </td>
              <td>Cookie nécessaire à la connexion et à l'authentification</td>
              <td>Durée de la session</td>
            </tr>
          </tbody>
        </table>
        <h3>8. Liens vers des sites internet tiers</h3>
        <p>
          Le site peut contenir des liens vers des sites internet ou application
          de tiers, qui sont régis par leurs propres politiques de
          confidentialité, conditions d’utilisation et mesures de sécurité qui
          peuvent différer de celles du Cabinet Doré. Le Cabinet Doré ne peut
          être tenu responsable des pratiques de ces sites internet. Nous vous
          conseillons donc de consulter leur politique de confidentialité avant
          de leur transmettre toute information ou donnée vous concernant.
        </p>
        <h3>9. Modification des termes de la Politique de confidentialité</h3>
        <p>
          Le cabinet Doré se réserve le droit de modifier cette Politique de
          Confidentialité à tout moment en fonction des évolutions légales et
          réglementaires. Nous vous encourageons à consulter cette page
          régulièrement pour rester informé des pratiques en vigueur.
        </p>
        <p>Dernière mise à jour le 10/01/2024</p>
      </div>
    </div>
  );
};

export default Privacy;
