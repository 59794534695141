import React from "react";
import InnerPage from "../../components/InnerPage/InnerPage";

function Stranger(props) {
  const tableauObjets = [
    {
      title: "Titre de Séjour",
      content:
        "Procédures d'obtention, renouvellement, conseils et recours en cas de refus",
    },
    {
      title: "Visas",
      content: "Conditions d'obtention, conseils et recours en cas de refus",
    },
    {
      title: "Nationalité",
      content:
        "Accompagnement à la demande, conseils et recours en cas de refus",
    },
    {
      title: "Asile",
      content:
        "Assistance devant l’OFPRA et la CNDA, procédure de réexamen de la demande d’asile",
    },
    {
      title: "Regroupement Familial",
      content:
        "Etude des conditions d'éligibilité, conseils, et recours en cas de refus",
    },
    {
      title: "Travail",
      content:
        "Autorisation de travail, contrats de travail pour les étrangers, régularisation liée à l'emploi",
    },

    {
      title: "Consultation Personnalisée",
      content:
        "Services de consultation juridique, analyse individualisée des situations, conseils personnalisés",
    },
  ];
  return (
    <div>
      <div>
        <InnerPage
          InnerTitle="Droit des étrangers"
          InnerSubTitle="Dans le vaste domaine du droit, les règles concernant les personnes étrangères sont
          parfois compliquées et changent souvent. Maître Camille Doré, avocate en droit des
          étrangers, se propose comme une alliée fiable. Elle offre son soutien professionnel
          pour guider ses clients à travers les démarches administratives. Son engagement et
          sa détermination en font une option solide pour ceux qui recherchent une assistance
          juridique équilibrée."
          InnerBanner="./images/stranger_compress.png"
          InnerImage="/images/stager-image_compress.jpg"
          InnerDropdown={tableauObjets}
        />
      </div>
    </div>
  );
}

export default Stranger;
