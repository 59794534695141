import React, { useState } from "react";
import "./Dropdown.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const Dropdow = (data) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isClick, setIsClick] = useState("-cliked");
  const [isShow, setIsShow] = useState("show");

  function click() {
    if (isOpen === false) {
      setIsOpen(true);
      setIsClick("-cliked");
      setIsShow("show");
    } else {
      setIsOpen(false);
      setIsShow("");
    }
  }

  return (
    <div className="dropdown">
      <div className="title" onClick={click}>
        <h4>{data.title}</h4>
        {!isOpen && (
          <div className={"arrow" + isClick}>
            <KeyboardArrowDownIcon />
          </div>
        )}
        {isOpen && (
          <div className={"arrow" + isClick}>
            <KeyboardArrowUpIcon />
          </div>
        )}
      </div>
      <div className={"collapse " + isShow}>
        <div className="description">{data.description}</div>
      </div>
    </div>
  );
};

export default Dropdow;
