import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./Modify.css";
import ReactQuill from "react-quill"; // Importez React Quill
import "react-quill/dist/quill.snow.css"; // Styles pour React Quill

function Modify(props) {
  const params = useParams();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");

  const [selectedFile, setSelectedFile] = useState({ preview: "", data: "" });
  const [isModified, setisModified] = useState(false);
  const [show, setShow] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [error, setError] = useState("");

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleContentChange = (value) => {
    setContent(value);
  };

  function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }
  const token = getCookie("_auth");
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + token,
  };

  function handleFileInput(e) {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setSelectedFile(img);
  }

  const sectionShow = (e) => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const validation = (e) => {
    if (showValidation) {
      setShowValidation(false);
    } else {
      setShowValidation(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (title !== "") {
      formData.append("title", title);
    }
    if (content !== "") {
      formData.append("content", content);
    }
    if (author !== "") {
      formData.append("author", author);
    }
    if (selectedFile.data) {
      formData.append("file", selectedFile.data);
      console.log(selectedFile.data);
    }

    try {
      const response = await axios.put(
        `https://api.dore-avocat.fr/api/post/${params.id}`,
        formData,
        {
          headers: headers,
        }
      );
      console.log(response);
      setisModified(true);
      setError("");
      window.location.reload();
    } catch (err) {
      console.error(err);
      setisModified(false);
      if (err.response && err.response.status !== 500) {
        setError(err.response.data.error);
      } else {
        setError("Erreur serveur");
      }
    }
  };

  const handleClick = (e) => {
    axios
      .delete(`https://api.dore-avocat.fr/api/post/${params.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        console.log(response);
        setIsDelete(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="modify-section">
      <h1>Modifer l'article</h1>
      <div className="form-and-button">
        {error && <p className="info">{error} </p>}
        {isModified && (
          <p className="valiation">votre article a bien été enregistré. </p>
        )}
        {!isDelete && (
          <div className="article-Section">
            <form className="article-Form" action="" onSubmit={handleSubmit}>
              <div className="article-input">
                <label htmlFor="title">Titre </label>
                <input
                  className="article-title"
                  type="text"
                  name="article-title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="article-input">
                <label htmlFor="content"> Contenu </label>
                <ReactQuill
                  className="article-content"
                  value={content}
                  onChange={handleContentChange}
                  modules={modules}
                  formats={formats}
                />
              </div>
              <div>
                <label htmlFor="author">Auteur </label>
                <input
                  className="author"
                  type="text"
                  name="author"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </div>
              <div className="file-input">
                <input
                  className="article-input"
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={handleFileInput}
                ></input>
              </div>
              {selectedFile.preview && (
                <img
                  src={selectedFile.preview}
                  alt="profil"
                  width="100"
                  height="100"
                />
              )}

              <p className="btn-modify" onClick={validation}>
                Publier l'article
              </p>
              {showValidation && (
                <div>
                  <p>Vous voulez vraiment modifer l'article ?</p>
                  <button className="btn-not-delete" type="submit">
                    Oui
                  </button>
                  <button className="btn-delete" onClick={validation}>
                    Non
                  </button>
                  {isDelete && <p>L'article a été supprimé</p>}
                </div>
              )}
            </form>
          </div>
        )}
        <p className="btn-delete" onClick={sectionShow}>
          Supprimer l'article
        </p>
        {show && (
          <div>
            <p>Vous voulez vraiment supprimer l'article définitivement ?</p>
            <button className="btn-delete" onClick={handleClick}>
              Oui
            </button>
            <button className="btn-not-delete" onClick={sectionShow}>
              Non
            </button>
            {isDelete && <p>L'article a été supprimé</p>}
          </div>
        )}
      </div>
      {error && <p className="info">{error} </p>}
    </div>
  );
}

export default Modify;
