import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import Cookies from "js-cookie";
import { useAuth } from "../AuthProvider";

import "./Login.css";

const Login = ({ onFormSwitch }) => {
  const signIn = useSignIn();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const [error, setError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [passwordSend, setPasswordSend] = useState(false);
  const [passwordSendAlerte, setPasswordSendAlerte] = useState(false);

  const { handleLogin } = useAuth(); // Accédez à la fonction de connexion du contexte

  useEffect(() => {
    if (isSubmitted === true) {
      navigate("/dashboard");
    }
  }, [isSubmitted, navigate]);

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    axios
      .post("https://api.dore-avocat.fr/blog/user/login", {
        email,
        password,
      })
      .then((response) => {
        signIn({
          token: response.data.token,
          expiresIn: 3600,
          tokenType: "Bearer",
          authState: { email: email },
        });
        setIsSubmitted(true);
        Cookies.set("serverResponse", JSON.stringify(response.data), {
          expires: 7,
        });
        handleLogin(); // Appeler la fonction de connexion du contexte après la connexion réussie
      })
      .catch((err) => {
        setIsSubmitted(false);
        if (err.response.status !== 500) {
          setError(err.response.data.error);
        } else {
          setError("erreur serveur");
        }
      });
  };

  const resetPassword = (e) => {
    setIsReset(true);
  };

  const handelClick = (e) => {
    e.preventDefault();

    axios
      .post(`https://api.dore-avocat.fr/blog/user/forgot-password`, {
        email,
      })
      .then((response) => {
        setPasswordSend(true);
        setPasswordSendAlerte(false);

        console.log(response);
      })
      .catch((err) => {
        setPasswordSendAlerte(true);
        setPasswordSend(false);

        console.log(err.message);
      });
  };

  return (
    <div className="form-section">
      <div className="form">
        {passwordSend && <p className="valiation">Un email a été envoyé. </p>}
        {passwordSendAlerte && (
          <p className="valiation-error">
            Impossible de réinitialiser le mot de passe.{" "}
          </p>
        )}
        <form action="" onSubmit={handleSubmitLogin}>
          <h2>Se connecter</h2>
          <p className="info">{error}</p>
          <div>
            <label htmlFor="email"> Email </label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="password"> Mot de passe </label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className="btn-submit" type="submit">
            Connexion
          </button>
        </form>
      </div>
      <button className="reset-btn" onClick={resetPassword}>
        Mot de passe oublié
      </button>

      {passwordSend && <p className="valiation">Un email a été envoyé. </p>}
      {passwordSendAlerte && (
        <p className="valiation-error">
          Impossible de réinitialiser le mot de passe.{" "}
        </p>
      )}
      {isReset && (
        <div>
          <button className="reset-btn" onClick={handelClick}>
            Cliquer ici pour réinitialiser le mot de passe
          </button>
        </div>
      )}
    </div>
  );
};

export default Login;
