import React from "react";
import { NavLink } from "react-router-dom";
import Nav from "../../components/Nav/Nav";
import "./Error.css";
const Error = () => {
  return (
    <div className="error">
      <Nav />
      <div className="error-section">
        <img
          className="logo-connection"
          src="/images/logocabinet.png"
          alt="logo"
        />
        <h1 className="errorTitle">Erreur 404</h1>
        <p className="message">Oups! La page que vous demandez n'existe pas.</p>
        <NavLink to="/">
          <button className="back"> Retourner sur la page d'accueil</button>
        </NavLink>
      </div>
    </div>
  );
};

export default Error;
