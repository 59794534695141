import React from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import "./Cookie.css";

const Cookie = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  return (
    <div className="cookie-consent">
      <p>
        Notre site utilise exclusivement des cookies indispensables pour assurer
        son bon fonctionnement. Aucun cookie tiers n'est employé sur ce site.{" "}
        <Link to="/confidentialité">
          <div className="option">Politique de confidentialité</div>
        </Link>{" "}
      </p>
      <button onClick={giveCookieConsent}>Ok</button>
    </div>
  );
};

export default Cookie;
