import React from "react";
import InnerPage from "../../components/InnerPage/InnerPage";

function Penal(props) {
  const tableauObjets = [
    {
      title: "Représentation légale en cas d'accusations criminelles",
      content:
        "Fournir une défense juridique robuste pour ceux qui font face à des accusations criminelles.",
    },
    {
      title: "Assistance en enquête",
      content:
        "Offrir un soutien juridique dès le stade de l'enquête pour assurer une procédure équitable.",
    },
    {
      title: "Plaidoyer de culpabilité",
      content:
        "Conseiller et représenter les clients envisageant de plaider coupable, en négociant des peines favorables.",
    },
    {
      title: "Procès criminel",
      content:
        "Fournir une représentation complète lors des procès criminels pour garantir une défense vigoureuse.",
    },
    {
      title: "Appels criminels",
      content:
        "Représenter les clients dans le processus d'appel pour contester les verdicts ou les peines.",
    },
    {
      title: "Victimes de crime",
      content:
        "Assister les victimes de crimes en poursuivant des actions en justice, en demandant des indemnisations, et en les guidant tout au long du processus juridique.",
    },
    {
      title: "Conseils en droit pénal",
      content:
        "Fournir des conseils juridiques complets sur les questions liées au droit pénal, que ce soit avant, pendant ou après une affaire.",
    },
    {
      title: "Expulsion et libération conditionnelle",
      content:
        "Aider les clients à naviguer à travers les processus d'expulsion et de libération conditionnelle, en travaillant pour obtenir des résultats favorables.",
    },
  ];
  return (
    <div>
      <div>
        <InnerPage
          InnerTitle="Droit Pénal"
          InnerSubTitle="Maître Camille Doré travaille dans le domaine du droit pénal et assure une
          représentation forte et efficace dans différentes situations. Sa priorité est la justice et
          les droits de ses clients, ce qui guide sa pratique pour défendre vos intérêts avec
          passion et intégrité."
          InnerBanner="./images/penal_compress.png"
          InnerImage="/images/inner_compress.png"
          InnerDropdown={tableauObjets}
        />
      </div>{" "}
    </div>
  );
}

export default Penal;
