import Content from "../../components/Content/Content";
import Modify from "../../components/Modify/Modify";
import Nav from "../../components/Nav/Nav";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../components/AuthProvider";
import "./OnaArticle.css";
import axios from "axios";
import { NavLink, useParams } from "react-router-dom";

function OneArticle(props) {
  const params = useParams();
  const [Article, setArticle] = useState([]);

  function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }
  const token = getCookie("_auth");
  useEffect(() => {
    axios
      .get(`https://api.dore-avocat.fr/api/post/${params.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setArticle(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id, token]);
  const { isLoggedIn } = useAuth();
  console.log(isLoggedIn);
  return (
    <div>
      <Nav />
      <div className="modify-content">
        {isLoggedIn && Article.length !== 0 && <Modify />}
        <Content />
      </div>
      {Article.length === 0 && (
        <div className="error">
          <div className="error-section">
            <img
              className="logo-connection"
              src="/images/logocabinet.png"
              alt="logo"
            />
            <h1 className="errorTitle">Cet article n'existe pas</h1>
            <p className="message">
              Oups! La page que vous demandez n'existe pas.
            </p>
            <NavLink to="/">
              <button className="back"> Retourner sur la page d'accueil</button>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
}

export default OneArticle;
