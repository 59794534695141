import React from "react";
import ContactForm from "../../components/Contact/ContactForm";
import Nav from "../../components/Nav/Nav";

function PageContact(props) {
  return (
    <div>
      <Nav />
      <ContactForm />{" "}
    </div>
  );
}

export default PageContact;
