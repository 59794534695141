import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom"; // Assurez-vous d'utiliser React Router pour gérer les paramètres d'URL
import Nav from "../../components/Nav/Nav";
import "./ResetPasswordPage.css";

const ResetPasswordPage = () => {
  const params = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [passwordSend, setPasswordSend] = useState(false);

  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const digitRegex = /\d/;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Les mots de passe ne correspondent pas");
      setPasswordSend(false);
    } else if (
      password.length < 8 ||
      !(
        uppercaseRegex.test(password) &&
        lowercaseRegex.test(password) &&
        digitRegex.test(password)
      )
    ) {
      setMessage(
        "Le mot de passe doit avoir au moins 8 caractères et contenir au moins une majuscule, une minuscule et un chiffre !"
      );
      setPasswordSend(false);
    } else {
      axios
        .post(
          `https://api.dore-avocat.fr/blog/user/reset-password/${params.token}`,
          {
            password: confirmPassword,
          }
        )
        .then((response) => {
          setPasswordSend(true);
          setMessage("");
          console.log(response);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  return (
    <div>
      {" "}
      <Nav />
      <div className="reset-page">
        <h2>Réinitialisation du mot de passe</h2>
        {passwordSend && (
          <p className="valiation"> le mot de passe a été modifié. </p>
        )}
        <p className="valiation-error"> {message} </p>
        <div className="reset-form">
          <form onSubmit={handleSubmit}>
            <div>
              <label>Nouveau mot de passe :</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Confirmer le nouveau mot de passe :</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Réinitialiser le mot de passe</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
