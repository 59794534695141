import React from "react";
import Nav from "../../components/Nav/Nav";

import "../About/About.css";

function About(props) {
  return (
    <div>
      <div>
        <div className="Profil_banner">
          <Nav />
          <div className="Profil_banner_content">
            <h1 className="page_title">A propos</h1>
          </div>
        </div>
        <div className="about_subtile">
          Maître Camille Doré est avocate à Lille. Elle a étudié le droit
          international et obtenu son Certificat d'Aptitude à la Profession
          d'Avocat (CAPA) en 2021. Initialement impliquée dans le secteur
          associatif en tant que bénévole et militante pour l'accès au droit
          pour tous, elle est convaincue que la connaissance des droits est
          essentielle pour leur exercice effectif. Ainsi, elle a choisi de
          consacrer sa carrière à aider chaque individu, indépendamment de ses
          ressources, à comprendre et à faire valoir ses droits. Après avoir
          travaillé dans deux cabinets d'avocats, Maître Camille Doré a créé son
          propre cabinet, en accord avec ses formations et convictions. Elle
          souhaite fournir à ses clients un soutien juridique de qualité, adapté
          à leurs besoins spécifiques.
        </div>
        <div className="profil">
          <div className="profil-image">
            <h2>Engament associatif </h2>
            <img src="./images/camille_compress.jpg" alt="camille"></img>
          </div>

          <div className="profil-description">
            <h3> Fondation de l'Association Pangéa (2016)</h3>
            <p>
              <br />
              En 2016, inspirée par la mission de l'Université de Lille envers
              les jeunes en exil souhaitant reprendre leurs études, Maître
              Camille Doré a fondé l'association Pangéa. Le nom, qui signifie «
              toutes les terres » en grec, incarne son engagement à faciliter
              l'intégration des étudiants étrangers. Au fil des années,
              l’association a accompagné plus de 600 étudiants étrangers grâce à
              la mobilisation de plus de 200 bénévoles.
            </p>
            <h3> Stage auprès de "La Cimade" (2019)</h3>
            <p>
              <br />
              En 2019, Maître Camille Doré a effectué son stage de fin d'études
              au sein de l'association "La Cimade". Son choix s'est porté sur le
              groupe de Perpignan afin de comprendre les pratiques liées à une
              nouvelle frontière. Cette expérience a renforcé sa compréhension
              des enjeux liés à la migration et a enrichi sa perspective
              juridique.
            </p>
          </div>
        </div>
        <div className="profil_part2">
          <h3> Stage à la Plateforme de soutien aux migrants (2020)</h3>
          <p>
            <br />
            En 2020, Maître Camille Doré a consacré six mois de sa carrière à un
            stage au sein de l'association "La Plateforme de soutien aux
            migrants". Sa mission consistait à documenter les contentieux
            collectifs à la frontière franco-britannique sur la dernière
            décennie. Son investissement au cours de cette mission lui a permis
            de développer une expertise précise sur les questions juridiques
            spécifiques liées à la migration dans cette région sur les dix
            dernières années.
          </p>
          <h3> Reconnaissances de son Engagement</h3>
          <p>
            <br />
            Le dévouement associatif de Maître Camille Doré a été honoré par
            deux distinctions significatives :
          </p>
          <ul>
            <li className="distinction">
              Le Prix de la Solidarité décerné par la Métropole Européenne de
              Lille et l’Université de Lille accordé à Pangéa en 2019.
            </li>
            <li className="distinction">
              Le titre de Prodige de la République attribué par le gouvernement
              français en 2021.
            </li>
          </ul>

          <p>
            Ces reconnaissances soulignent l'impact positif de ses actions et
            renforcent son engagement à poursuivre la lutte pour la justice
            sociale et les droits de l'homme. En tant qu'avocate, Maître Camille
            Doré met cette passion et cette expérience au service de ses clients
            pour garantir une représentation juridique éclairée et engagée.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
