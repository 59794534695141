import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import Nav from "../Nav/Nav";
import "./innerPage.css";

function InnerPage(props) {
  console.log(props.InnerDropdown);
  return (
    <div>
      <div
        className="inner_banner"
        style={{ backgroundImage: `url(${props.InnerBanner})` }}
      >
        <Nav />
        <div className="inner_banner_content">
          <h1 className="page_title">{props.InnerTitle}</h1>
        </div>
      </div>
      <div className="inner_page">
        <div className="inner_page-image">
          <img src={props.InnerImage} alt={props.InnerTitle}></img>
        </div>
        <div className="Drop-menus">
          <p>{props.InnerSubTitle}</p>

          {props.InnerDropdown.map((element, index) => (
            <Dropdown title={element.title} description={element.content} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default InnerPage;
