import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./NavDropDown.css";

const NavDropDown = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelect = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);

    // Naviguer vers la page correspondante en fonction de l'option sélectionnée
    if (selectedValue === "étranger") {
      navigate("/étranger");
    } else if (selectedValue === "pénal") {
      navigate("/pénal");
    } else if (selectedValue === "famille") {
      navigate("/famille");
    }
  };
  return (
    <div className="all-domaine">
      <select
        className="domaine-selection"
        value={selectedOption}
        onChange={handleSelect}
      >
        <option value="">Domaines d’expertise</option>
        <option value="étranger">Droit des étrangers</option>
        <option value="pénal">Droit pénal</option>
        <option value="famille">Droit de la famille</option>
      </select>
    </div>
  );
};

export default NavDropDown;
