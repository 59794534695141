import React from "react";
import { useSignOut } from "react-auth-kit";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const LogBtn = () => {
  const singOut = useSignOut();
  const navigate = useNavigate();
  const { handleLogout } = useAuth();
  const { isLoggedIn } = useAuth();

  const logout = () => {
    singOut();
    handleLogout();
    navigate("/");
  };
  return (
    <div>
      {isLoggedIn && (
        <button className="btnlogOut" onClick={logout}>
          Déconnexion
        </button>
      )}
      {!isLoggedIn && (
        <Link to="/connexion">
          <button>Connexion</button>
        </Link>
      )}
    </div>
  );
};

export default LogBtn;
