import Login from "../../components/Login/Login";
import Nav from "../../components/Nav/Nav";
// import Signup from "../Component/Signup";

// import "../Style/login.css";

function Connection() {
  return (
    <div>
      <Nav />
      <div className="login">
        <div className="login__header">
          <div className="login__logo">
            <img
              className="logo-connection"
              src="../images/logocabinet_compress.png"
              alt="logocabinet"
            />
          </div>
          <h1>Espace privé</h1>
        </div>
        <div className="login-page">
          <Login />
        </div>
      </div>
    </div>
  );
}

export default Connection;
