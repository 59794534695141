import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Content.css";

function Content(props) {
  const params = useParams();
  const [Article, setArticle] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");

  function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }
  const token = getCookie("_auth");
  useEffect(() => {
    axios
      .get(`https://api.dore-avocat.fr/api/post/${params.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setArticle(data);
        const dateObject = new Date(data.createdAt);
        setFormattedDate(dateObject.toLocaleDateString());
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id, token]);

  console.log(Article.length);
  return (
    <div>
      {Article.length !== 0 && (
        <div className="content">
          {Article.photo == null && (
            <img
              className="Article-image-default"
              src="../images/logocabinet.png"
              alt="logocabinet"
            />
          )}
          {Article.photo != null && (
            <img
              className="Article-image"
              src={Article.photo}
              alt="logocabinet"
            />
          )}

          <div className="Article">
            <div className="Article-hearder">
              <h2 className="Article-title">{Article.title}</h2>

              <div className="Article-info">
                <p className="Article-author">Auteur: {Article.author}</p>
                <p className="Article-date">Publié le: {formattedDate}</p>
              </div>
            </div>
            <div className="Article-content">
              <p dangerouslySetInnerHTML={{ __html: Article.content }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Content;
