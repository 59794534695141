import React, { useState } from "react";
import axios from "axios";
import Nav from "../../components/Nav/Nav";
import "./Article.css";
import ReactQuill from "react-quill"; // Importez React Quill
import "react-quill/dist/quill.snow.css"; // Styles pour React Quill

function Article(props) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");

  const [selectedFile, setSelectedFile] = useState({ preview: "", data: "" });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleContentChange = (value) => {
    setContent(value);
  };

  function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }
  const token = getCookie("_auth");
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + token,
  };

  function handleFileInput(e) {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setSelectedFile(img);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("file", selectedFile.data);
    formData.append("author", author);

    try {
      const response = await axios.post(
        "https://api.dore-avocat.fr/api/post",
        formData,
        {
          headers: headers,
        }
      );
      console.log(response);
      setIsSubmitted(true);
      setError("");
    } catch (err) {
      console.error(err);
      setIsSubmitted(false);
      if (err.response && err.response.status !== 500) {
        setError(err.response.data.error);
      } else {
        setError("Erreur serveur");
      }
    }
  };
  return (
    <div>
      <Nav />
      <div className="article">
        <h1>Ecrire un nouvel article</h1>
        <div>
          {error && <p className="info">{error} </p>}
          {isSubmitted && (
            <p className="valiation">votre artilce a bien été publié. </p>
          )}
          <div className="article-Section">
            <form className="article-Form" action="" onSubmit={handleSubmit}>
              <div className="article-title">
                <label htmlFor="title">Titre </label>
                <input
                  className="article-title"
                  type="text"
                  name="article-title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="article-content">
                <label htmlFor="content"> Contenu </label>
                <ReactQuill
                  className="article-content"
                  value={content}
                  onChange={handleContentChange}
                  modules={modules}
                  formats={formats}
                />
              </div>
              <div>
                <label htmlFor="author">Auteur </label>
                <input
                  className="author"
                  type="text"
                  name="author"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </div>
              <div className="file-uploader">
                <input
                  className="article-input"
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={handleFileInput}
                ></input>
              </div>
              {selectedFile.preview && (
                <img
                  src={selectedFile.preview}
                  alt="profil"
                  width="100"
                  height="100"
                />
              )}

              <button className="CTA" type="submit">
                Publier l'article
              </button>
            </form>
          </div>
          {isSubmitted && (
            <p className="valiation">votre artilce a bien été publié. </p>
          )}
          {error && <p className="info">{error} </p>}
        </div>
      </div>
    </div>
  );
}

export default Article;
