import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import Error from "./pages/Error/Error.jsx";
import About from "./pages/About/About";
import Calendar from "./pages/Calendar/Calendar";
import PageContact from "./pages/PageContact/PageContact";
import Familly from "./pages/Familly/Familly";
import Stranger from "./pages/Stranger/Stranger";
import Penal from "./pages/Penal/Penal";
import Connection from "./pages/Connection/Connection";
import Dashboard from "./pages/Dashboard/Dashboard";
import { RequireAuth } from "react-auth-kit";
import Article from "./pages/Article/Article";
import OneArticle from "./pages/OneArticle/OneArticle";
import { AuthProvider } from "./components/AuthProvider";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage.jsx";
import Privacy from "./pages/Privacy/Privacy.jsx";
import Legal from "./pages/Legal/Legal.jsx";
import Cookie from "./components/Cookie/Cookie.jsx";
import { useCookies } from "react-cookie";

function App() {
  const [cookies] = useCookies(["cookieConsent"]);

  return (
    <BrowserRouter>
      <div className="page">
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="reset-password/:token"
              element={<ResetPasswordPage />}
            />

            <Route path="*" element={<Error />} />
            <Route path="apropos" element={<About />} />
            <Route path="rdv" element={<Calendar />} />
            <Route path="contact" element={<PageContact />} />
            <Route path="famille" element={<Familly />} />
            <Route path="étranger" element={<Stranger />} />
            <Route path="pénal" element={<Penal />} />
            <Route path="connexion" element={<Connection />} />
            <Route path="article/:id" element={<OneArticle />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="confidentialité" element={<Privacy />} />
            <Route path="légal" element={<Legal />} />

            <Route
              path="article"
              element={
                <RequireAuth loginPath="/connexion">
                  <Article />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/article/:id"
              element={
                <RequireAuth loginPath="/connexion">
                  <OneArticle />
                </RequireAuth>
              }
            />
          </Routes>
        </AuthProvider>
      </div>
      {!cookies.cookieConsent && <Cookie />}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
