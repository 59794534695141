import React from "react";
import Nav from "../../components/Nav/Nav";
import "./Legal.css";
const Legal = () => {
  return (
    <div>
      <Nav />

      <div className="legal">
        <h1>Mentions légales</h1>
        <h3>Editeur du site </h3>
        <p>
          Le site https://dore-avocat.fr/ est la propriété de Maître Camille
          Doré dont le cabinet se trouve au 22 rue Lamarck - 80000 Amiens
        </p>
        <p>Tél. : 03.22.72.55.10</p>
        <p>Courriel : avocat.dore@gmail.com</p>
        <h3>Directrice de publication</h3>
        <p>Maître Camille Doré</p>
        <h3>Conception du site</h3>
        <p>Sité réalisé par Marine Kouadio </p>
        <p>Courriel : marinekouadio@gmail.com</p>
        <h3>Hébergement</h3>
        <p>OVH</p>
        <p>SAS au capital de 10 174 560 €</p>
        <p>Siège social : 2 rue Kellermann - 59100 Roubaix - France</p>
        <p>
          OVH SAS est une filiale de la société OVH Groupe SA, société
          immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2, rue
          Kellermann, 59100 Roubaix.
        </p>
        <p>
          {" "}
          Ce site est hébergé par OVH SAS 2 rue Kellermann - 59100 Roubaix -
          France
        </p>
        <h3>Propriété intellectuelle</h3>{" "}
        <p>
          La structure générale du site internet ainsi que les textes sont la
          propriété du cabinet Doré.
        </p>
        <p>
          Toute reproduction complète ou partielle de ce site internet par toute
          entité ou personne, sans l'autorisation explicite du cabinet Doré, est
          strictement interdite et constituerait une violation des droits
          d'auteur conformément au code de la propriété intellectuelle.
        </p>
        <p>Le nom de domaine du site Internet est une marque déposée.</p>
        <p>
          Toute reproduction totale ou partielle réalisée à partir des éléments
          du site internet sans l'autorisation explicite du cabinet Doré est
          donc formellement interdite, en accord avec les dispositions du Code
          de la propriété intellectuelle.
        </p>
        <p>
          Toute reproduction non autorisée est susceptible d'entraîner la
          responsabilité de l'auteur en vertu de l'article 1240 du code civil.
        </p>
        <h3>Modification</h3>
        <p>
          Le cabinet Doré se réserve le droit de modifier et de mettre à jour
          ces mentions légales à tout moment et sans notification préalable.
        </p>
      </div>
    </div>
  );
};

export default Legal;
