import React from "react";
import InnerPage from "../../components/InnerPage/InnerPage";

function Familly(props) {
  const tableauObjets = [
    {
      title: "Divorce et Séparation",
      content:
        "Gestion des procédures de divorce amiable ou contentieux. Négociation des accords de séparation.",
    },
    {
      title: "Garde d'Enfants",
      content:
        "Défense des droits parentaux. Résolution des litiges liés à la garde et au droit de visite.",
    },
    {
      title: "Pension Alimentaire",
      content:
        "Évaluation et négociation des montants de pension alimentaire. Représentation dans les contentieux liés aux obligations financières.",
    },
    {
      title: "Liquidation du Régime Matrimonial",
      content:
        "Conseils en matière de partage des biens Gestion des aspects juridiques liés à la liquidation du régime matrimonial.",
    },
    {
      title: "Adoption et Tutelle",
      content:
        "Accompagnement dans les procédures d'adoption. Représentation dans les affaires de tutelle.",
    },
  ];
  return (
    <div>
      <InnerPage
        InnerTitle="Droit de la famille"
        InnerSubTitle="Le cabinet vous propose des solutions juridiques personnalisées et empathiques
        pour accompagner chaque aspect délicat de votre situation familiale. Faites
        confiance à Maître Camille Doré pour des conseils juridiques adaptés à vos besoins."
        InnerBanner="./images/family_compress.png"
        InnerImage="/images/familly-paper-compress.jpg"
        InnerDropdown={tableauObjets}
      />
    </div>
  );
}

export default Familly;
